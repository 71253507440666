* {
  box-sizing: border-box;
}

html {
  background-color: $wit;
}

body {
  font-family: $font-family-body;
  color:$grijs-text;
  font-weight: 300;
  height: 100%;
  line-height: 1.8;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

.message {
  @extend .alert;
  &.good {
    @extend .alert-success;
  }
}

.mobile-header-list {
  @include media-breakpoint-down(md){
    @include rem(min-width, 180px);
  }
}

.btn-outline-primary {
  background: $white;
  border-color: transparentize($primaire-kleur, 0.65);
}

.btn {
    z-index: 25;
    position: relative;
}

.nav-image{
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  &::before{
    content:'';
    background: linear-gradient(to right, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
  }
}

.element-products-price {
  @include rem(font-size, 20px);
}

.collapsing {
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

.action {
  cursor: pointer;
}

.btn{
  @include rem(border-radius, 60px);
}

.nav-cart-icon {
  .badge {
    position: absolute;
  }
}

.padded-item {
  @include rem(padding, 45px);
  height:100%;
  background-position: bottom center;
  background-repeat: repeat-x;

  @include media-breakpoint-between(md, xl) {
    @include rem(padding, 45px);
  }
  @include media-breakpoint-between(sm, md) {
    @include rem(padding, 45px);
  }
  @include media-breakpoint-between(xs, sm) {
    @include rem(padding, 30px);
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding, 15px);
  }
}

.dropdown {
  select {
    background: $white;
  }
}

select {
  &.dropdown {
    background: $white;
  }
}

.default-container{
  @include rem(padding, 0px 150px);
  @include media-breakpoint-between(md, xl) {
    @include rem(padding, 0px 75px);
  }
  @include media-breakpoint-between(sm, md) {
    @include rem(padding, 0px 50px);
  }
  @include media-breakpoint-between(xs, sm) {
    @include rem(padding, 0px 50px);
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding, 0px 20px);
  }
}

.element-padded-big {
  @include rem(margin-top, 60px);
  @include rem(margin-bottom, 60px);

  @include rem(padding-top, 60px);
  @include rem(padding-bottom, 60px);

  @include media-breakpoint-between(md, xl) {
    @include rem(margin-top, 45px);
    @include rem(margin-bottom, 45px);

    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }
  @include media-breakpoint-between(sm, md) {
    @include rem(margin-top, 45px);
    @include rem(margin-bottom, 45px);

    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }
  @include media-breakpoint-between(xs, sm) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);

    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 30px);
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);

    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 30px);
  }
}

.element-padded-medium {
  @include rem(margin-top, 45px);
  @include rem(margin-bottom, 45px);

  @include rem(padding-top, 45px);
  @include rem(padding-bottom, 45px);

  &.element-bg-image,
  .element-bg{
    @include rem(margin-top, 0);
    @include rem(margin-bottom, 0);

    @include rem(padding-top, 60px);
    @include rem(padding-bottom, 60px);
  }

  @include media-breakpoint-down(sm) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);

    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 30px);

    &.element-bg-image,
    .element-bg{
      @include rem(margin-top, 0);
      @include rem(margin-bottom, 0);

      @include rem(padding-top, 60px);
      @include rem(padding-bottom, 60px);
    }
  }
}

.element-padded {
  @include rem(margin-top, 30px);
  @include rem(margin-bottom, 30px);

  @include rem(padding-top, 30px);
  @include rem(padding-bottom, 30px);

  &.element-bg-image,
  .element-bg{
    @include rem(margin-top, 0);
    @include rem(margin-bottom, 0);

    @include rem(padding-top, 60px);
    @include rem(padding-bottom, 60px);
  }

  //@include media-breakpoint-down(sm) {
  //  @include rem(padding-top, 30px);
  //  @include rem(padding-bottom, 30px);
  //}
}

.block-item {
  background-position: bottom center;
  background-repeat: repeat-x;
  border: 2px solid transparentize($primaire-kleur, .75);
}

.element-round {
  border-radius: 12px;
  overflow: hidden;
}

.element-light {
  background-color: $beige;
}

.element-bg {
  background-color: $white;
  border-radius: 6px;
}

.element-bg-image{
  background-position: top center;
  background-repeat: repeat-x;
}

.image-wrap{
  img {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 6px;
  }
}

.with-shadow{
  position: relative;
  display: block;
  z-index: 20;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);

  img {
    position: relative;
    z-index: 20;
  }

}
