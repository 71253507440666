.sub-categories {
  .categorie-sub-item {
    z-index: 5;
    position: relative;
  }

  .categorie-item {
    @include rem(min-height, 300px);
    border-radius: 6px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;

    &.categorie-item-image {
      &:before{
        content: '';
        width: 100%;
        height: 100%;
        background: $primaire-kleur;
        opacity: 0.4;
        top: 0;
        left: 0;
        position: absolute;
      }
      &:after{
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, transparentize($primaire-kleur, 0.5), transparent);
        top: 0;
        left: 0;
        position: absolute;
      }
      .categorie-title {
        color: $wit;
      }
      .btn {
        color: $primaire-kleur;
      }
    }
  }

  .categorie-link {
    &:hover {
      text-decoration: none;
    }
  }
}
