/* Mixins */
@import "mixins/rem";
@import "mixins/fonts";
@import "mixins/box-shadow-alt";

/* Theme Variables */
@import "base/variables";


/* Bootstrap */
@import "../../node_modules/bootstrap/scss/functions";
@import "bootstrap/fixes"; // SilverStripe Bootstrap fixes
@import "bootstrap/variables"; // Overide Bootstraps variables here
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
//@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
//@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
//@import "../../node_modules/bootstrap/scss/progress";
//@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
//@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

/* Swiper Slider */
//@import "../../node_modules/swiper/dist/css/swiper.css";

/* PhotoSwipe */
//@import "../../node_modules/photoswipe/dist/photoswipe.css";
//@import "../../node_modules/photoswipe/dist/default-skin/default-skin.css";

@import "../../node_modules/@staaky/fresco/dist/css/fresco.css";

/* DatePicker */
//@import "../../node_modules/js-datepicker/dist/datepicker.min.css";

/* Slider Jquery UI */
//@import "../../node_modules/jquery-ui/themes/base/slider.css";

/* Slick Slider */
//@import "../../node_modules/slick-carousel/slick/slick.css";

/* Magnific Popup */
//@import "../../node_modules/magnific-popup/dist/magnific-popup.css";

/* Base */
@import "base/global";

/* Typography */
@import "typography/headings";
@import "typography/paragraphs";
@import "typography/tables";

/* Layout */
@import "layout/header";
@import "layout/footer";

/* Pages */
// @import "pages/search-results";
// @import "pages/cart-page";
// @import "pages/category-page";
// @import "pages/account-page";
// @import "pages/product-page";
// @import "pages/checkout-page";
// @import "pages/arrangementen-page";
// @import "pages/arrangement";

/* Components */
@import "components/navbar";
@import "components/breadcrumbs";
@import "components/panel";
@import "components/back-to-top";
@import "components/forms";
@import "components/section-navigation";
//@import "components/topbar";
// @import "components/banner-block";
@import "components/imagecontent-block";
@import "components/subcategories";
// @import "components/calltoaction-block";
// @import "components/callout-block";
// @import "components/news-block";
// @import "components/meldingen";
// @import "components/products-block";
// @import "components/special-block";
// @import "components/cards-block";
// @import "components/gallery-block";
// @import "components/gallery-animations";
// @import "components/login";
// @import "components/element-blocks";
 @import "components/element-usps";
// @import "components/element-contact";
@import "components/cookies";
// @import "components/video-banner";

/* Forms */
@import "forms/select";
@import "forms/search";
@import "forms/userform";
