/* Variables */

// Fonts
$font-family-body: "Raleway", Helvetica, Arial, sans-serif;
$font-family-headings: "Crimson Text", serif;

$primaire-kleur: #2b969b;
$secundaire-kleur: #9e2063;

$beige: #f5f5f5;
$wit: #ffffff;
$goud: #e8d373;
$groen: #324937;
$groen-alt: #829a4e;
$groen-text: #adb5af;
$fel-groen: #a3d733;
$fel-groen-alt: #1bb518;
$grijs-text: #5d6373;
$grijs-text-alt: #999999;
$grijs-dark: #20202c;
$zwart: #000000;
$stepbg: #f7f7f7;
