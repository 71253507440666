.section-navigation {
  background: $primaire-kleur;
  .nav {
    .nav-item {
      .nav-link {
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        @include rem(font-size, 21px);
        &.btn-outline-light{
          &:hover{
            color:$primaire-kleur;
          }
        }
        .active {

        }
      }
    }
  }
}
