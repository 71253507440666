.element-usps {
  i {
    color: $primaire-kleur;
    @include rem(font-size, 18px);
    @include rem(width, 60px);
    @include rem(height, 60px);
    @include rem(padding, 15px);
    text-align:center;
    display: flex;
    align-items: center;
    border-radius: 100%;
    background: $white;
    justify-content: center;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);
  }
  .element-usps--title {
    font-weight: 700;
    font-family: $font-family-body;
    @include rem(font-size, 18px);

    @include media-breakpoint-down(md){
      border-bottom: 1px solid $gray-400;
      @include rem(margin, 10px 0!important);
    }
  }
}
