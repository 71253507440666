/* Headings */

h1,
h2,
h3,
h4,
h5,
h6, {
  font-family: $font-family-headings;
  color:$primaire-kleur;
}
h1 {
  @include rem(font-size, 32px);
  font-weight: 500;
  line-height: 1.1;
}
h2 {
  @include rem(font-size, 28px);
  font-weight: 500;
  line-height: 1.1;
}
h3 {
  @include rem(font-size, 26px);
  font-weight: 500;
  line-height: 1.1;
}
h4 {
  @include rem(font-size, 24px);
  font-weight: 500;
  line-height: 1.1;
}
h5 {
  @include rem(font-size, 24px);
  font-weight: 500;
  line-height: 1.1;
}
h6 {
  @include rem(font-size, 24px);
  font-weight: 500;
  line-height: 1.1;
}

.left-title {
  font-family: $font-family-headings;
  color:$primaire-kleur;
  @include rem(font-size, 32px);
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 0;
  word-break: break-word;
  @include media-breakpoint-down(lg){
    @include rem(font-size, 26px);
  }
  span{
    display: block;
  }
}
.left-title-sans {
  font-family: $font-family-body;
  color:$primaire-kleur;
  @include rem(font-size, 24px);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;
  word-break: break-word;
  @include media-breakpoint-down(lg){
    @include rem(font-size, 21px);
  }
  span{
    display: block;
  }
}

.default-title{
  word-break: break-word;
  font-family: $font-family-headings;
  color:$primaire-kleur;
  @include rem(font-size, 32px);
  font-weight: 500;
  line-height: 1.1;
  margin-bottom: 0;
  @include media-breakpoint-down(lg){
    @include rem(font-size, 26px);
  }
}
