/* Breadcrumbs */
.breadcrumb {
  background-color: transparent;
  padding: 0;
  @include rem(margin-bottom, 20px);
}

.breadcrumb-item a {
  color: #212529;
}
